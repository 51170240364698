<template>
  <v-card
    :style="
      $vuetify.breakpoint.xs
        ? 'padding: 12px; margin: 4px'
        : 'padding: 18px; margin: 0 18px 0 18px'
    "
  >
    <v-row>
      <v-col
        cols="12"
        sm="12"
        lg="12"
        xl="12"
        class="pt-10"
        :style="'text-align: center;'"
      >
        Ops! Não há permissão para acessar o recurso solicitado. Entre em contato com o administrador.
      </v-col>
      <v-col
        cols="12"
        sm="12"
        lg="12"
        xl="12"
        class="pt-10"
        :style="'text-align: center;'"
      >
        {{ textRedirecionamento }}
      </v-col>
      <v-col
        cols="12"
        sm="12"
        lg="12"
        xl="12"
        class="pt-10"
        :style="'text-align: center;'"
      >
        <v-btn
          class="text-none text-white"
          color="primary"
          large
          title="Redirecionar agora"
          @click="redirecionar()"
        >
          Redirecionar agora
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import SemPermissaoStore, { START, REDIRECIONAR } from '@/store/modules/semPermissao'

  export default {
    name: 'SemPermissao',
    components: {

    },
    data () {
      return {
      }
    },
    computed: {
      ...mapState('semPermissao', ['counting', 'seconds']),
      textRedirecionamento () {
        return 'Você será redirecionado em ' + this.seconds + ' segundos ...'
      },
    },
    created () {
      if (!this.$store.hasModule('semPermissao')) { this.$store.registerModule('semPermissao', SemPermissaoStore) }
    },
    mounted () {
      this.START({ user: this.$user, router: this.$router })
    },
    methods: {
      ...mapActions('semPermissao', [START, REDIRECIONAR]),
      redirecionar () {
        this.REDIRECIONAR({ user: this.$user, router: this.$router })
      },
    },
  }
</script>

<style>
</style>
